import React, { useEffect, useState, useRef } from "react";
import { Space, Table, Tag, DatePicker, Button, Checkbox } from "antd";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../../database/firebase";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { Input } from "antd";
import dayjs from "dayjs";

import { DeleteOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { deleteDataDoc, getDataDoc, updateDocDatabase } from "../../database";
import userEvent from "@testing-library/user-event";
import withErrorBoundary from "../../Components/withErrorComponent";

const Condica = ({ profesorData, dateDefault = new Date(), print = false }) => {
  const [date, setDate] = useState(dateDefault);
  const months = [
    "Ianuarie 2024",
    "Februarie 2024",
    "Martie 2024",
    "Aprilie 2024",
    "Mai 2024",
    "Iunie 2024",
    "Iulie 2024",
    "August 2024",
    "Septembrie 2023",
    "Octombrie 2023",
    "Noiembrie 2023",
    "Decembrie 2023",
  ];

  const clase = useSelector((state) => state.clase);
  const materii = useSelector((state) => state.materii);
  const [data, setData] = useState();
  const [administare, setAdministare] = useState(false);
  const [condica, setCodica] = useState([]);
  const componentRef = useRef();
  const [concedii, setConcedii] = useState([]);
  const [semnaturi, setSemnaturi] = useState({});
  const profesori = useSelector((state) => state.profesori);
  useEffect(() => {
    let sem = {};
    const fetchSemnaturi = async () => {
      for await (let prof of profesori) {
        if (prof.semnatura) {
          let semnaturaLink = prof.semnatura;
          const response = await fetch(semnaturaLink);
          const blob = await response.blob();

          // Creează un URL local din blob și setează-l în state
          const localUrl = URL.createObjectURL(blob);
          sem[prof.id] = localUrl;
        } else sem[prof.id] = "prezent";
      }
      setSemnaturi(sem);
    };
    fetchSemnaturi();
  }, [profesori]);
  const checkForDublicates = (zi, hour, id) => {
    if (!(typeof zi?.toLocaleDateString == "function")) return;
    let ziRender = zi.toLocaleDateString("ro-RO");
    let hourRender = retriveHourCorectly(hour, "start");
    let findDublicate = condica
      .filter((c) => !c?.materieId?.includes("Admin"))
      .filter((c) => !(c?.startDate?.getHours() >= 20))
      .find((c) => {
        let curRender =
          (c.zi
            ? new Date(c.zi).toLocaleDateString("ro-RO")
            : c.startDate.toLocaleDateString("ro-RO")) +
          retriveHourCorectly(c.startDate, "start");

        if (c.id !== id && ziRender + hourRender === curRender) return true;
        return false;
      });
    if (findDublicate) return {};
    return {};
  };
  const retriveHourCorectly = (date, type) => {
    if (type === "start") {
      if (date.getHours() < 10) return "0" + date.getHours() + ":" + "00";
      else return date.getHours() + ":10";
    }
    if (type === "end") {
      if (date.getHours() < 10) return "0" + date.getHours() + ":" + "50";
      else return date.getHours() + 1 + ":00";
    }
  };
  const fetchDat = async () => {
    const querySnapshot = await getDocs(collection(db, "concedii"));

    let array = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      array.push({
        ...doc.data(),
        startDate: new Date(doc.data().startDate),
        endDate: new Date(doc.data().endDate),
        id: doc.id,
      });
    });
    setConcedii(array);
  };

  useEffect(() => {
    fetchDat();
  }, [profesorData]);
  const onChange = (date, dateString) => {
    setDate(date?.toDate());
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const concediu = (data, standalones) => {
    if (typeof data === "number" || typeof data === "string")
      data = new Date(data);

    if (
      (concedii || []).find(
        (c) =>
          c.prof === profesorData.id &&
          c.startDate.getTime() <= data &&
          c.endDate.getTime() >= data &&
          (!standalones.getHours ||
            (retriveHourCorectly(standalones, "start") >=
              (c.stringDates?.[0] || "00:00") &&
              retriveHourCorectly(standalones, "start") <=
                (c.stringDates?.[1] || "23:59")))
      )?.text === "CONCEDIU MEDICAL"
    )
      return { color: "red" };
    if (
      (concedii || []).find(
        (c) =>
          c.prof === profesorData.id &&
          c.startDate.getTime() <= data &&
          c.endDate.getTime() >= data &&
          (!data.getHours ||
            (retriveHourCorectly(data, "start") >=
              (c.stringDates?.[0] || "00:00") &&
              retriveHourCorectly(data, "start") <=
                (c.stringDates?.[1] || "23:59")))
      )?.text === "CONCEDIU"
    )
      return { color: "green" };
    return {};
  };

  const columns = [
    {
      title: "Condica",
      dataIndex: "condica",
      key: "condica",
      render: (e, data) => {
        const date = () => {
          if (data.zi)
            return (
              <p
                style={{
                  ...concediu(data.zi, data.startDate),
                  ...checkForDublicates(
                    new Date(data.zi),
                    data.startDate,
                    data.id
                  ),
                }}
              >
                {new Date(data.zi).toLocaleDateString("ro-RO")}
              </p>
            );
          return (
            <p
              style={{
                ...concediu(data.startDate, data.startDate),
                ...checkForDublicates(data.startDate, data.startDate, data.id),
              }}
            >
              {data.startDate.toLocaleDateString("ro-RO")}
            </p>
          );
        };
        const interval = () => {
          if (data.tip === "toataziua")
            return <p style={concediu(data.zi, data.startDate)}>-</p>;
          return (
            <p
              style={{
                ...concediu(data.zi || data.startDate, data.startDate),
                ...checkForDublicates(
                  data.zi || data.startDate,
                  data.startDate,
                  data.id
                ),
              }}
            >
              {retriveHourCorectly(data.startDate, "start")} -{" "}
              {retriveHourCorectly(data.endDate, "end")}
            </p>
          );
        };
        const materie = () => {
          return (
            <>
              {data.tip === "privat" ? (
                <p style={concediu(data.zi || data.startDate, data.startDate)}>
                  {data.textOra || data.classId || data.materieId}
                </p>
              ) : (
                <p style={concediu(data.zi || data.startDate, data.startDate)}>
                  {materii?.find((m) => m.id === data.materieId)?.numeMaterie}
                </p>
              )}
            </>
          );
        };
        const clasa = () => {
          if (data.tip === "toataziua") return <p>-</p>;
          return (
            <>
              {data.tip === "privat" ? (
                <p style={concediu(data.zi || data.startDate, data.startDate)}>
                  -
                </p>
              ) : (
                <p style={concediu(data.zi || data.startDate, data.startDate)}>
                  {clase.find((cls) => cls.id === data.classId)?.anClasa +
                    clase.find((cls) => cls.id === data.classId)?.identificator}
                </p>
              )}
            </>
          );
        };
        const subiect = () => {
          return (
            <Input.TextArea
              value={data.subiectLectie}
              onChange={async (e) => {
                await updateDocDatabase(
                  profesorData.id +
                    "condica" +
                    date?.getMonth() +
                    "an" +
                    date?.getFullYear(),
                  data.uuid,
                  {
                    subiectLectie: e.target.value,
                  }
                );
              }}
            />
          );
        };

        return (
          <div style={{ textAlign: "center" }}>
            <p>{date()}</p>
            <p>{interval()}</p>
            <p>{materie()}</p>
            <p>{clasa()}</p>
            <p>{subiect()}</p>
          </div>
        );
      },
      responsive: ["xs"],
    },
    {
      title: "Data",
      dataIndex: "Data",
      key: "data",
      render: (e, data) => {
        if (data.zi)
          return (
            <p
              style={{
                ...concediu(data.zi, data.startDate),
                ...checkForDublicates(
                  new Date(data.zi),
                  data.startDate,
                  data.id
                ),
              }}
            >
              {new Date(data.zi).toLocaleDateString("ro-RO")}
            </p>
          );
        return (
          <p
            style={{
              ...concediu(data.startDate, data.startDate),
              ...checkForDublicates(new Date(data.zi), data.startDate, data.id),
            }}
          >
            {data.startDate.toLocaleDateString("ro-RO")}
          </p>
        );
      },
      responsive: ["sm"],
    },
    {
      title: "Interval",
      dataIndex: "interval",
      key: "interval",
      render: (e, data) => {
        if (data.tip === "toataziua")
          return <p style={concediu(data.zi, data.startDate)}>-</p>;
        return (
          <p
            style={{
              ...concediu(data.zi || data.startDate, data.startDate),
              ...checkForDublicates(new Date(data.zi), data.startDate, data.id),
            }}
          >
            {retriveHourCorectly(data.startDate, "start")} -
            {retriveHourCorectly(data.endDate, "end")}{" "}
          </p>
        );
      },
      responsive: ["sm"],
    },
    {
      title: "Materie",
      dataIndex: "Materie",
      key: "Materie",
      render: (e, data) => {
        return (
          <>
            {data.tip === "privat" ? (
              <p style={concediu(data.zi || data.startDate, data.startDate)}>
                {data.textOra || data.classId || data.materieId}
              </p>
            ) : (
              <p style={concediu(data.zi || data.startDate, data.startDate)}>
                {materii?.find((m) => m.id === data.materieId)?.numeMaterie}
              </p>
            )}
          </>
        );
      },
      responsive: ["sm"],
    },
    {
      title: "Clasa",
      dataIndex: "Clasa",
      key: "clasa",
      render: (e, data) => {
        if (data.tip === "toataziua") return <p>-</p>;
        return (
          <>
            {data.tip === "privat" ? (
              <p style={concediu(data.zi || data.startDate, data.startDate)}>
                -
              </p>
            ) : (
              <p style={concediu(data.zi || data.startDate, data.startDate)}>
                {clase.find((cls) => cls.id === data.classId)?.anClasa +
                  clase.find((cls) => cls.id === data.classId)?.identificator}
              </p>
            )}
          </>
        );
      },
      responsive: ["sm"],
    },

    {
      title: "Subiect Lectie",
      key: "action",
      render: (_, data) => (
        <Input.TextArea
          value={data.subiectLectie}
          onChange={async (e) => {
            await updateDocDatabase(
              profesorData.id +
                "condica" +
                date?.getMonth() +
                "an" +
                date?.getFullYear(),
              data.uuid,
              {
                subiectLectie: e.target.value,
              }
            );
          }}
        />
      ),
      responsive: ["sm"],
    },

    {
      title: "Tip",
      key: "tip",
      render: (_, data) => (
        <p style={concediu(data.zi || data.startDate, data.startDate)}>
          {concedii?.find(
            (c) =>
              c.prof === profesorData.id &&
              c.startDate.getTime() <= (data.zi || data.startDate?.getTime()) &&
              c.endDate.getTime() >= (data.zi || data.startDate?.getTime()) &&
              (!data.startDate.getHours ||
                (retriveHourCorectly(data.startDate, "start") >=
                  (c.stringDates?.[0] || "00:00") &&
                  retriveHourCorectly(data.startDate, "start") <=
                    (c.stringDates?.[1] || "23:59")))
          )?.text || "✔️"}
        </p>
      ),
      responsive: ["sm"],
    },
    !print
      ? {
          title: "Sterge",
          key: "actiondelete",
          render: (_, data) => (
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={async () => {
                await deleteDataDoc(
                  profesorData.id +
                    "condica" +
                    date?.getMonth() +
                    "an" +
                    date?.getFullYear(),
                  data.uuid
                );
              }}
            />
          ),
          responsive: ["sm"],
        }
      : {},
  ];
  const columnsAdmin = [
    {
      title: "Data",
      dataIndex: "Data",
      key: "data",
      render: (e, data) => {
        if (data.zi)
          return (
            <p
              style={{
                ...concediu(
                  data.zi || data.startDate,
                  data.startDate,
                  data.profId
                ),
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              {new Date(data.zi).toLocaleDateString("ro-RO")}
            </p>
          );
        return (
          <p
            style={{
              ...concediu(
                data.zi || data.startDate,
                data.startDate,
                data.profId
              ),
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            {data.startDate.toLocaleDateString("ro-RO")}
          </p>
        );
      },
      responsive: ["sm"],
    },

    {
      title: "Nume și prenume",
      dataIndex: "prof",
      key: "prof",
      render: (e, data) => {
        return (
          <>
            <p
              style={{
                ...concediu(
                  data.zi || data.startDate,
                  data.startDate,
                  data.profId
                ),
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              {profesorData.displayName}
            </p>
          </>
        );
      },
      responsive: ["sm"],
    },

    {
      title: "Semnătură",
      key: "tip",

      render: (_, data) => {
        if (semnaturi[data.profId] === "prezent")
          return (
            <p
              style={{
                ...concediu(
                  data.zi || data.startDate,
                  data.startDate,
                  data.profId
                ),
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              {concedii?.find(
                (c) =>
                  c.prof === data.profId &&
                  c.startDate.getTime() <=
                    (data.zi || data.startDate?.getTime()) &&
                  c.endDate.getTime() >=
                    (data.zi || data.startDate?.getTime()) &&
                  (!data.startDate.getHours ||
                    (retriveHourCorectly(data.startDate, "start") >=
                      (c.stringDates?.[0] || "00:00") &&
                      retriveHourCorectly(data.startDate, "start") <=
                        (c.stringDates?.[1] || "23:59")))
              )?.text ||
                (data.vacanta
                  ? "Concediu de odihnă"
                  : "prezent " +
                    (profesori?.find((p) => data?.profId == p.id)?.programAdmin
                      ? "în timpul programului de muncă " +
                        profesori?.find((p) => data?.profId == p.id)
                          ?.programAdmin[
                          (new Date(data.startDate).getDay() + 6) % 7
                        ]
                      : ""))}
            </p>
          );
        else {
          if (
            Object.keys(
              concediu(data.zi || data.startDate, data.startDate, data.profId)
            ).length > 0
          ) {
            return (
              <p
                style={{
                  ...concediu(
                    data.zi || data.startDate,
                    data.startDate,
                    data.profId
                  ),
                  fontSize: "12px",
                }}
              >
                {concedii
                  ?.find(
                    (c) =>
                      c.prof === data.profId &&
                      c.startDate.getTime() <=
                        (data.zi || data.startDate?.getTime()) &&
                      c.endDate.getTime() >=
                        (data.zi || data.startDate?.getTime()) &&
                      (!data.startDate.getHours ||
                        (retriveHourCorectly(data.startDate, "start") >=
                          (c.stringDates?.[0] || "00:00") &&
                          retriveHourCorectly(data.startDate, "start") <=
                            (c.stringDates?.[1] || "23:59")))
                  )
                  ?.text(
                    data.vacanta
                      ? "Concediu de odihnă"
                      : "prezent " +
                        profesori.find((p) => data.profId == p.id).programAdmin
                      ? "în timpul programului de muncă " +
                        profesori?.find((p) => data.profId == p.id)
                          ?.programAdmin[
                          ((data.zi || data.startDate).getDate() + 6) % 7
                        ]
                      : ""
                  )}
              </p>
            );
          } else
            return (
              <img
                src={semnaturi[data.profId]}
                style={{ width: "100px", height: "30px" }}
              />
            );
        }
      },
      responsive: ["sm"],
    },
    !print
      ? {
          title: "Sterge",
          key: "actiondelete",
          responsive: ["sm"],
          width: "10px",
          render: (_, data) => (
            <div style={{ margin: "auto" }}>
              <Button
                danger
                style={{ marginLeft: "20px" }}
                icon={<DeleteOutlined />}
                onClick={async () => {
                  await deleteDataDoc(
                    profesorData.id +
                      "condica" +
                      date?.getMonth() +
                      "an" +
                      date?.getFullYear(),
                    data.uuid
                  );
                }}
              />
            </div>
          ),
        }
      : {},
  ];

  const fetchData = async () => {
    const querySnapshot = await getDocs(
      collection(
        db,
        profesorData.id +
          "condica" +
          date?.getMonth() +
          "an" +
          date?.getFullYear()
      )
    );

    let array = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      array.push({
        ...doc.data(),
        uuid: doc.id,
        startDate: new Date(doc.data().startDate),
        endDate: new Date(doc.data().endDate),

        displayName: profesorData.numeDeFamilie + " " + profesorData.prenume,
        profId: profesorData.id,
      });
    });
    setCodica(array);
  };

  useEffect(() => {
    fetchData();
    const unsub3 = onSnapshot(
      collection(
        db,
        profesorData.id +
          "condica" +
          date?.getMonth() +
          "an" +
          date?.getFullYear()
      ),
      (doc) => {
        fetchData();
      }
    );
    return unsub3;
  }, [date]);
  function subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months);
    return date;
  }

  return (
    <>
      <h1>Condica</h1>
      <h3>{print === true && months[date.getMonth()]}</h3>
      <br />
      {!print && (
        <div className="no-print">
          <Space>
            <LeftOutlined
              onClick={() => setDate(subtractMonths(new Date(date), 1))}
            />
            <DatePicker
              onChange={onChange}
              picker="month"
              placeholder="Luna"
              value={dayjs(date)}
            />
            <RightOutlined
              onClick={() =>
                setDate(new Date(date.setMonth(date.getMonth() + 1)))
              }
            />
          </Space>
        </div>
      )}
      <br />
      <br />
      <div ref={componentRef} className="condica-export">
        {profesorData.selectedMaterii?.find((c) => c?.includes("Admin")) && (
          <Table
            columns={columnsAdmin}
            dataSource={condica
              .filter((c) => c?.materieId?.includes("Admin"))
              .sort((a, b) => {
                return a.zi - b.zi;
              })}
            pagination={false}
          />
        )}
        <br />
        <Table
          columns={columns}
          dataSource={condica
            .filter((c) => !c?.materieId?.includes("Admin"))
            .filter(
              (c) =>
                c.tip === "toataziua" ||
                (c.tip !== "toataziua" && !(c?.startDate?.getHours() >= 20))
            )
            .sort((a, b) => {
              if (
                new Date(
                  a?.zi || a?.startDate.getTime()
                ).toLocaleDateString() ===
                new Date(b?.zi || b?.startDate.getTime()).toLocaleDateString()
              )
                return a?.startDate.getHours() - b?.startDate.getHours();
              return (
                new Date(a?.zi || a?.startDate.getTime()) -
                new Date(b?.zi || b?.startDate.getTime())
              );
            })}
          pagination={false}
        />
      </div>
      <br />
      {!print && (
        <div>
          <h3>Adauga instanta pe toata ziua</h3>
          <Space>
            {profesorData?.selectedMaterii?.find((c) =>
              c?.includes("Admin")
            ) && (
              <Checkbox
                value={administare}
                onChange={(e) => setAdministare(e.target.checked)}
              />
            )}
            <DatePicker
              placeholder="zi"
              onChange={(e) => {
                setData(e.toDate());
              }}
            />{" "}
            <Button
              onClick={async () => {
                if (typeof data?.getTime === "function") {
                  await updateDocDatabase(
                    profesorData.id +
                      "condica" +
                      data.getMonth() +
                      "an" +
                      data.getFullYear(),
                    data.getDate() +
                      "zi" +
                      data.getMonth() +
                      "an" +
                      data.getFullYear(),
                    {
                      subiectLectie: "",
                      startDate: data.getTime(),
                      tip: "toataziua",
                    }
                  );
                  if (administare === true)
                    await updateDocDatabase(
                      profesorData.id +
                        "condica" +
                        data.getMonth() +
                        "an" +
                        data.getFullYear(),
                      data.getDate() +
                        "zi" +
                        data.getMonth() +
                        "an" +
                        data.getFullYear(),
                      {
                        subiectLectie: "",
                        startDate: data.getTime(),
                        tip: "toataziua",
                        materieId: "Admin",
                      }
                    );
                }
              }}
            >
              Adauga zi
            </Button>
          </Space>
          <br /> <br />
          <Button
            onClick={() => {
              handlePrint();
            }}
            type="primary"
            danger
          >
            Printează
          </Button>
        </div>
      )}
    </>
  );
};
export default withErrorBoundary(Condica);
